<mat-card class="line-chart-card">
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
    <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <canvas id="lineChart"></canvas>
  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-button>Reload</button>
  </mat-card-actions> -->
</mat-card>
