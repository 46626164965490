<mat-card class="line-chart-card">
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
    <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="chartcontainer col-md-12 col-lg-4">
        <canvas id="advancedPieChart"></canvas>
      </div>
      <div class="details col-md-12 col-lg-8">
        <div class="row">
          <div class="total col-12 px-0">
            <div class="counter col-12 px-0">
              {{total}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="total col-12 px-0 px-lg-1">
            <div class="subtitle col-12 px-0">
              Total
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col pl-0" *ngFor="let det of details">
            <div class="pointer col-12" [ngStyle]="{'border-color': det.color}">
              <div class="col-md-12 px-0 counter">
                {{det.num}}
              </div>
              <div class="col-md-12 px-0 item">
                {{det.text}}
              </div>
              <div class="col-md-12 px-0 percent">
                {{det.percent}}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-button>Reload</button>
  </mat-card-actions> -->
</mat-card>
