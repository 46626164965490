import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { IChartData } from '../../../interfaces/chart-data';

@Component({
  selector: 'line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
})
export class LineComponent implements OnInit {
  private chart: any = null;
  @Input() title = 'Line';
  @Input() subtitle = 'Subtitulo';
  @Input() chartData: IChartData;

  constructor(
  ) { }

  ngOnInit() {
    this.chart = new Chart('lineChart', {
      type: 'line',
      data: {
        labels: [],
        datasets: [
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: Chart.axisLineColor,
              },
              ticks: {
                fontColor: Chart.textColor,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: Chart.axisLineColor,
              },
              ticks: {
                fontColor: Chart.textColor,
              },
            },
          ],
        },
        legend: {
          labels: {
            fontColor: Chart.textColor,
          },
        }
      }
    });
    if (this.chartData) {
      this.updateChart()
    }
  }


  updateChart(): void {
    this.chart.data.labels = this.chartData.labels;
    this.chart.data.datasets = this.chartData.datasets;
    this.chart.update();
  }

}
