import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { Store, Menu } from '../classes/store';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  selectedStore: Store;
  selectedStoreSubject = new Subject<Store>();
  stores: Store[];
  storesSubject = new Subject<Store[]>();
  sidebarOpts: Menu[];
  sidebarOptsSubject = new Subject<Menu[]>();

  constructor(
    private auths: AuthService,
    private router: Router
  ) {
    this.auths.userSubject
      .subscribe(user => {
        this.stores = user.stores;
        const [first] = user.stores;
        this.selectStore(first._id);
        this.storesSubject.next(this.stores);
      })
  }

  getStores(): Observable<Store[]> {
    return of(this.stores);
  }

  getStore(_id: string): Observable<Store> {
    return new Observable(obs => {
      const store = this.stores.find(sh => sh._id === _id);
      obs.next(store);
    })
  }

  selectStore(_id: string) {
    const store = this.stores.find(store => store._id === _id);
    if (store) {
      this.selectedStore = store;
      this.selectedStoreSubject.next(store);
      this.sidebarOpts = store.menu;
      this.sidebarOptsSubject.next(store.menu);
      this.resolveRoute();
    } else {
      this.selectedStoreSubject.next();
      this.sidebarOptsSubject.next();
    }
  }

  resolveRoute() {
    const flatRoutes = this.sidebarOpts.map(menu => menu.route.join('/'));
    if(flatRoutes.indexOf(this.router.url) < 0) {
      const [first] = this.sidebarOpts;
      this.router.navigate(first.route);
    }
  }

}
