import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { IChartData } from '../../../interfaces/chart-data';

export interface IDetails {
  text: string;
  color: string;
  num: number;
  percent: number;
}
@Component({
  selector: 'advanced-pie',
  templateUrl: './advanced-pie.component.html',
  styleUrls: ['./advanced-pie.component.scss']
})
export class AdvancedPieComponent implements OnInit {
  private chart: any = null;
  @Input() title = 'Advanced Pie';
  @Input() subtitle = 'Subtitulo';
  @Input() chartData: IChartData
  details: IDetails[];
  total: any;

  constructor() { }

  ngOnInit(): void {
    this.chart = new Chart('advancedPieChart', {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        showTooltips: true,
      }
    });
    if (this.chartData) {
      this.updateChart()
    }
  }


  updateChart(): void {
    const { labels, datasets } = this.chartData;
    this.chart.data.labels = labels;
    this.chart.data.datasets = datasets;
    this.chart.update();

    const [{ data, borderColor }] = datasets;

    this.total = data.reduce((init, set) => { return set + init }, 0);
    this.details = labels.map((lab, indx) => {
      const num = data[indx];
      const col = borderColor[indx];
      const percent = ((num / this.total) * 100).toFixed(1);
      return {
        text: lab,
        color: col,
        num,
        percent
      } as unknown as IDetails;
    });
    // this.details = [
    //   { text: 'Two', cols: 1, rows: 1, color: 'lightgreen' },
    //   { text: 'Three', cols: 1, rows: 1, color: 'lightpink' },
    //   { text: 'Four', cols: 1, rows: 1, color: '#DDBDF1' },
    // ];
  }

}
