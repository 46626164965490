import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AutorizationInterceptor implements HttpInterceptor {

  constructor(
    private auths: AuthService
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const token = this.auths.gUser.idToken;
    if (!token) {
      return next.handle(req);
    }
    const authReq = req.clone({
      headers: req.headers.set('authorization', `GOOGLE ${token}`)
    });

    return next.handle(authReq);
  }
}
