import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser
} from 'angularx-social-login';
import { USER_LOCALSTORAGE_NAME } from '../config/config.constans';
import { Subject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LOGIN_URL } from '../../environments/environment';
import { User, IUser } from "../classes/user";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public gUser = new SocialUser();
  public gUserSubject = new Subject<SocialUser | undefined>();
  public loggedIn = false;
  public user: User;
  public userSubject = new Subject<User | undefined>();

  constructor(
    private authService: SocialAuthService,
    private http: HttpClient,
    private router: Router
  ) {
    this.authService.authState.subscribe(
      gUser => {
        this.gUser = gUser;
        this.loggedIn = this.isLoggedIn(this.gUser);
        if (this.loggedIn) {
          this.gUserSubject.next(gUser)
          localStorage.setItem(USER_LOCALSTORAGE_NAME, JSON.stringify(this.gUser));

          this.http.get<IUser>(LOGIN_URL)
            .subscribe(
              response => {
                this.user = new User(response);
                this.userSubject.next(this.user);
                const route = this.getRoute();
                this.router.navigate(route);
              },
              err => {
                this.goOut(err);
              });
        } else {
          this.goOut();
        }
      });
  }

  goOut(err?: any) {
    if (err) console.error(err);
    this.router.navigate(['login']);
    this.gUser = new SocialUser();
    localStorage.removeItem(USER_LOCALSTORAGE_NAME);
  }

  login(): void {
    try {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    } catch (error) {
      console.log(error);
    }
  }

  logOut(): void {
    this.authService.signOut();
  }

  isLoggedIn(user: SocialUser): boolean {
    return user !== null && Object.keys(user).length !== 0;
  }

  getRoute(_id?: string) {
    if (!_id) {
      const [{ menu }] = this.user.stores;
      return menu
    }
    const { menu } = this.user.stores.find(store => store._id === _id);
    return menu
  }

}
