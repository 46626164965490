import { Store } from "./store";

export interface IUser {
  email: string;
  name: string;
  stores: Store[];
}

export class User implements IUser {
  public email: string;
  public name: string;
  public stores: Store[];

  constructor(usr?: IUser) {
    if (!usr?.email) return;
    this.email = usr.email;
    this.name = usr.name;
    this.stores = usr.stores.map(store => new Store(store));
  }

}
