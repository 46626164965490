import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'admin-crestashop';
  activeSidebar: any;
  activeHeader: any;

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const route = event.state.root.firstChild;
        this.activeSidebar = route.data.activeSidebar || false;
        this.activeHeader = route.data.activeHeader || false;
      }
    });
  }
}
