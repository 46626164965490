<mat-toolbar color="primary">
  <div class="container-fluid">
    <div class="d-none d-lg-block">
      <div class="row align-items-center ">
        <div class="col-md-2">
          <img class="img-fluid" [src]="logo">
        </div>
        <div class="col-md-2">
          <span>TrasTienda</span>
        </div>
        <div class="col-2 offset-1" *ngIf="stores">
          <form class="form-pax" [formGroup]="storeForm">
              <mat-select class="select-mat" [(value)]="selected" formControlName="store">
                <mat-option *ngFor="let store of stores" [value]="store._id">{{store.name}}</mat-option>
              </mat-select>
          </form>
        </div>

        <div class="col-3 offset-1 px-0">
          <div class="row align-items-center justify-content-end">
            <div class="col-2 px-0">
              <img class="userphoto" [src]="gUser.photoUrl">
            </div>
            <div class="col-6 px-0">
              <span>{{gUser.firstName}}</span>
            </div>
          </div>
        </div>
        <div class="col-1 px-0">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="goLogout()">
              <mat-icon>account_box</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div class="row align-items-center">
        <div class="col-3">
          <img class="img-fluid" [src]="logo">
        </div>
        <div class="col-4" *ngIf="stores">
          <form class="form-pax" [formGroup]="storeForm">
            <mat-select [(value)]="selected" formControlName="store">
              <mat-option *ngFor="let store of stores" [value]="store._id">{{store.name}}</mat-option>
            </mat-select>
          </form>
        </div>

        <div class="col-1 offset-3 px-0">
          <img class="userphoto" [src]="gUser.photoUrl">
        </div>
        <div class="col-1 px-0">
          <button mat-icon-button [matMenuTriggerFor]="menuMob">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuMob="matMenu">
            <ng-container *ngIf="links">
              <button mat-menu-item [matMenuTriggerFor]="modules">
                <mat-icon>dashboard</mat-icon>
                <span>Modulos</span>
              </button>
            </ng-container>

            <button mat-menu-item (click)="goLogout()">
              <mat-icon>account_box</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
          <mat-menu #modules="matMenu">
            <button mat-menu-item *ngFor="let link of links" [routerLink]="link.route">
              <span>{{link.label}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

  </div>

</mat-toolbar>
