<div class="container">
  <ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="'users'">
      <user (handleRefresh)="getList()" *ngIf="header" [formData]="header"></user>
    </ng-container>
    <ng-container *ngSwitchCase="'coupons'">
      <coupon></coupon>
    </ng-container>
    <ng-container *ngSwitchCase="'loads'">
      <load-buttons (handleRefresh)="getList()" *ngIf="header" [buttons]="header"></load-buttons>
    </ng-container>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>

  <!-- <ng-container *ngIf="filters">
    <div class="container">
      <div class="row">
        <div class="col-12 px-0">
          <mat-card class="line-chart-card">
            <mat-card-content>
              <form class="user" [formGroup]="filterForm">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Filtro:</mat-label>
                      <mat-select formControlName="key">
                        <mat-option *ngFor="let filt of filters" [value]="filt.key">{{filt.placeholder}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-md-6">
                    <mat-form-field appearance="fill">
                      <input matInput placeholder="" formControlName="value" autocomplete="off" />
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-2">
                    <button [disabled]="filterForm.invalid" (click)="dofilter()" mat-raised-button>Filtrar</button>
                  </div>

                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-container> -->
  
  <div class="row">
    <div class="history-title">{{listTitle}}</div>
    <div class="col-12">
      <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner></mat-spinner>
      </div>
      <ng-container *ngIf="dataSource.data.length > 0">
        <table class="w-100" mat-table [dataSource]="dataSource" multiTemplateDataRows>
          <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
            <ng-container class="d-flex" [ngSwitch]="col">
              <ng-container *ngSwitchCase="'_actions'">
                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let col">
                  <ng-container *ngIf="col._actions">
                    <ng-container *ngFor="let act of col._actions">
                      <button mat-icon-button [matTooltip]="act.description" (click)="goLoad(col, act)">
                        <mat-icon>{{act.icon}}</mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'published'">
                <th mat-header-cell *matHeaderCellDef>Publicado</th>
                <td mat-cell *matCellDef="let row">
                  <span class="bind {{row[col]}}">{{row[col]}}</span></td>
              </ng-container>

              <ng-container *ngSwitchCase="'status'">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let row">
                  <mat-icon [ngClass]="row[col].class" [matTooltip]="row[col].description"> {{ row[col].icon }} </mat-icon>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'listActions'">
                <th mat-header-cell *matHeaderCellDef>Otras acciones</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngFor="let act of actions">
                    <button mat-icon-button [matTooltip]="act.description" (click)="action(act.code, element._id)">
                      <mat-icon>{{act.icon}}</mat-icon>
                    </button>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <th mat-header-cell *matHeaderCellDef>{{colDictionary[col]}}</th>
                <td mat-cell class="text-color" *matCellDef="let row">{{row[col]}}</td>
              </ng-container>

            </ng-container>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="rowElementDetail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <ng-container [ngSwitch]="detailRow">
                  <div *ngSwitchCase="'comments'">
                    <detail-comments (handleRefresh)="getList()" [element]="element"></detail-comments>
                  </div>
                  <div *ngSwitchCase="'orders'">
                    <detail-orders *ngIf="!!element._detail" [element]="element"></detail-orders>
                  </div>
                  <div *ngSwitchDefault></div>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="elementRow"
            [class.expandedRow]="expandedElement === element" (click)="expand(element);">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

        </table>
        <mat-paginator [hidden]="!!dataSource" [pageIndex]="page-1" [pageSize]="perPage" [length]="resultsLength" (page)="doPagination($event)" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </ng-container>

      <div class="row" *ngIf="!isLoadingResults && dataSource.data.length === 0">
        <div class="col-12">
          <mat-card class="line-chart-card">
            <mat-card-header>
              <h2>No he encontrado nada para listar 💔</h2>
            </mat-card-header>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
