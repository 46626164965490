export interface IMenu {
  label: string;
  route: string[];
  icon: string;
}

export class Menu implements IMenu {
  public label: string;
  public route: string[];
  public icon: string;
  constructor(mnu: IMenu) {
    this.label = mnu.label;
    this.route = mnu.route;
    this.icon = mnu.icon;
  }
}

export interface ITheming {
  logo: string;
}

export interface IStore {
  _id: string;
  name: string;
  roles: string[];
  menu: Menu[];
  theming: ITheming;
}

export class Store implements IStore {
  public _id: string;
  public name: string;
  public roles: string[];
  public menu: Menu[];
  public theming: ITheming;
  constructor(str: IStore) {
    this._id = str._id;
    this.name = str.name;
    this.roles = str.roles;
    this.menu = str.menu.map(menu => new Menu(menu));
    this.theming = str.theming;
  }
}
