import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// MATERIAL IMPORTS
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LineComponent } from './components/charts/line/line.component';
import { AdvancedPieComponent } from './components/charts/advanced-pie/advanced-pie.component';
import { TodayProfitComponent } from './components/charts/today-profit/today-profit.component';
import { ProfitComponent } from './components/ecomerce/profit/profit.component';
import { ComparedPipe } from './pipes/compared.pipe';
import { GoogleProviderId } from '../environments/environment';
import { ListComponent } from './components/list/list.component';
import { AutorizationInterceptor } from './core/interceptors/autorization.interceptor';
import { ResAuthInterceptor } from './core/interceptors/res-auth.interceptor';
import { RouterModule } from '@angular/router';
import { UserComponent } from './components/user/user.component';
import { DetailOrdersComponent } from './components/detail-orders/detail-orders.component';
import { DetailCommentsComponent } from './components/detail-comments/detail-comments.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { LoadButtonsComponent } from './components/load-buttons/load-buttons.component';
import { HtmlCreatorComponent } from './components/html-creator/html-creator.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    DashboardComponent,
    LineComponent,
    AdvancedPieComponent,
    TodayProfitComponent,
    ProfitComponent,
    ComparedPipe,
    ListComponent,
    UserComponent,
    DetailOrdersComponent,
    DetailCommentsComponent,
    CouponComponent,
    LoadButtonsComponent,
    HtmlCreatorComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    LayoutModule,
    SocialLoginModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              GoogleProviderId
            ),
          }
        ],
      } as SocialAuthServiceConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResAuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
