import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { Menu } from 'src/app/classes/store';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  activeSidebar: boolean;
  links: Menu[];
  
  constructor(
    private route: ActivatedRoute,
    private config: ConfigService
  ) {
    this.activeSidebar = false;
  }

  ngOnInit(): void {
    this.route.data
      .subscribe(data => {
        const { activeSidebar } = data;
        this.activeSidebar = activeSidebar;
      });

    this.links = this.config.sidebarOpts;
    this.config.sidebarOptsSubject
      .subscribe(links => this.links = links);
  }

}
