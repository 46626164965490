import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataService, IEntries, IActions, IFilters, IQuery } from '../../services/data.service';
import { ConfigService } from 'src/app/services/config.service';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, Validators, Form, FormGroup } from '@angular/forms';

@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*', padding: '0.2rem 0 1rem 0' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ListComponent implements OnInit, AfterViewInit {
  isLoadingResults: boolean;
  resultsLength: number;
  displayedColumns: string[];
  data: IEntries[];
  dataSource: MatTableDataSource<IEntries>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  flatColumns: string[];
  colDictionary = {};
  key: string;
  actions: IActions[];
  rolesTranslate: { [key: string]: string };
  expandedElement: IEntries;
  detailRow: string;
  header: any;
  prop: any;
  filterForm: FormGroup;
  filters: IFilters[];
  page: number;
  perPage: number;
  pageEvent: PageEvent;
  query: { key: any; value: any; };
  count: number;
  listTitle = '';

  constructor(
    private dataService: DataService,
    private config: ConfigService,
    private route: ActivatedRoute,
    public fb: FormBuilder
  ) {
    this.detailRow = '';
  }

  ngOnInit(): void {
    this.resultsLength = 0;
    this.isLoadingResults = true
    this.dataSource = new MatTableDataSource();

    this.route.paramMap.subscribe(params => {
      this.key = params.get('key');
      this.resetPagination();
      this.getList();
    });

    this.config.selectedStoreSubject
      .subscribe(store => this.getList());
    this.resetPagination();
    this.count = 0;
    this.setForm();

  }

  setForm() {
    const [first] = this.filters || [];
    const { key = '' } = first || {};
    this.filterForm = this.fb.group({
      key: [key, Validators.compose([
        Validators.required
      ])],
      value: ['', Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ])]
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  customTrackBy(index, item) {
    return item.key;
  }

  getList() {
    this.resultsLength = 0;
    this.isLoadingResults = true
    this.filters = undefined;
    this.displayedColumns = [];
    this.dataSource.data = [];
    const query = this.getQuery();
    const pagination = { page: this.page, perPage: this.perPage };
    this.listTitle = '';
    this.dataService.getList(this.key, this.config.selectedStore._id, pagination, query)
      .subscribe(
        ({ entries, render: { filters, columns, _actions = [], header, pagination, listTitle } }) => {
          this.page = pagination.page;
          this.perPage = pagination.perPage;

          this.displayedColumns = columns.map(col => {
            this.colDictionary[col.key] = col.title;
            return col.key
          });
          if (filters && filters.length > 0) {
            this.filters = filters;
          }
          if (_actions.length > 0) {
            this.displayedColumns.push('listActions');
            this.actions = _actions;
          }
          this.dataSource.data = entries;
          this.resultsLength = pagination.count;
          this.header = header;
          this.listTitle = listTitle;
        },
        err => {
          console.error(err);
          this.isLoadingResults = false;
        },
        () => this.isLoadingResults = false
      );
  }

  action(action, id) {
    console.log('action', action, 'id', id);
  }

  goLoad(element, action) {
    switch (action.code) {
      case 'comment-cart':
        this.prop = 2;
        this.expand(element);
        break;

      default:
        this.dataService.load(action.url, action.data)
          .subscribe(
            respone => console.log,
            err => console.error,
            () => this.getList()
          );
        break;
    }
  }

  expand(element) {
    this.expandedElement = undefined;
    this.detailRow = '';
    if (this.prop > 0) {
      this.prop = this.prop - 1;
      this.expandedElement = element;
      this.detailRow = 'comments';
    } else {
      if (element.hasOwnProperty('products')) {
        this.expandedElement = element;
        this.detailRow = 'orders';
      }
    }
  }

  doPagination(event?: PageEvent) {
    this.page = event.pageIndex + 1;
    this.perPage = event.pageSize;
    this.getList()
  }

  dofilter() {
    if (this.filterForm.valid) {
      const { key, value } = this.filterForm.value;
      this.query = {
        key: key,
        value: value
      };
      this.getList();
    }
  }

  getQuery(): IQuery {
    if (this.query?.key) {
      return { ...this.query };
    }
    return undefined
  }

  resetPagination() {
    this.page = 1;
    this.perPage = 10;
  }
}
