import { Component, OnInit, Input } from '@angular/core';
import { IProfit } from '../../../services/ecomerce.service';

@Component({
  selector: 'profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.scss']
})
export class ProfitComponent implements OnInit {
  @Input() profits: IProfit[];

  constructor(

  ) { }

  ngOnInit(): void {
    this.profits.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      return 0;
    });
  }

}
