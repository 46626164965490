<div class="container-fluid px-0">
  <div class="row">
    <header class="col-12 px-0" *ngIf="activeHeader"></header>
  </div>
  <div class="row">
    <sidebar *ngIf="activeSidebar" class="col-2 d-none d-sm-block"></sidebar>
    <div [ngClass]="(activeSidebar)?'col-md-10 col-12':'col-12'">
      <div class="d-flex flex-row">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
