import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService, IChartData } from '../../services/data.service';
import { EcomerceService, IProfit } from '../../services/ecomerce.service';
import { ConfigService } from '../../services/config.service';
import { Store } from 'src/app/classes/store';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  activeSidebar: any;
  activeHeader: any;
  realtimeChartData: any;
  profitData: IProfit[];
  lineChartData: IChartData;
  advancedPieData: IChartData;
  store: Store;

  constructor(
    private config: ConfigService,
    private dataService: DataService,
    private ecomerceService: EcomerceService
  ) { }

  ngOnInit(): void {
    this.store = this.config.selectedStore;
    this.config.selectedStoreSubject
      .subscribe(res => {
        this.store = res;
        this.init(this.store._id);
      });
    this.init(this.store._id);
  }

  init(storeId?: string) {
    this.lineChartData = undefined;
    this.advancedPieData = undefined;    
    this.profitData = undefined;    

    this.dataService.getChartLineData(storeId)
      .subscribe(res => {
        this.lineChartData = res;
      });

    this.dataService.getChartAdvancedPieData(storeId)
      .subscribe(res => {
        this.advancedPieData = res;
      });

    this.ecomerceService.getProfitData(storeId)
      .subscribe(res => {
        this.profitData = res;
      });
  }
}
