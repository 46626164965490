import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  activeSidebar: any;
  activeHeader: any;

  constructor(
    private auths: AuthService,
    private config: ConfigService
  ) { }

  ngOnInit(): void { }

  login(): void {
    this.auths.login();
  }
}
