<!-- <ng-container *ngIf="activeSidebar"> -->
  <div class="col-12">
    <mat-nav-list>
      <a mat-list-item [routerLink]="link.route" *ngFor="let link of links">
        <span *ngIf="link.icon"><mat-icon>{{link.icon}}</mat-icon></span>
        {{ link.label }} </a>
      <a mat-list-item [routerLink]="['tools']">
          <span><mat-icon>build</mat-icon></span>
          Tools
      </a>
    </mat-nav-list>
  </div>
<!-- </ng-container> -->