import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ListComponent } from './components/list/list.component';
import { HtmlCreatorComponent } from './components/html-creator/html-creator.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    data: {
      activeSidebar: false,
      activeHeader: false
    }
  },
  {
    path: 'tools',
    pathMatch: 'full',
    component: HtmlCreatorComponent,
    data: {
      activeSidebar: true,
      activeHeader: true
    }
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      activeSidebar: true,
      activeHeader: true
    }
  },
  {
    path: 'listados/:key',
    pathMatch: 'full',
    component: ListComponent,
    canActivate: [AuthGuard],
    data: {
      activeSidebar: true,
      activeHeader: true
    }
  },

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: '**', //component: DashboardComponent,
    redirectTo: 'listados/loads'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
