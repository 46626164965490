<mat-card>
  <mat-card-content class="container">
    <div class="row profit" *ngFor="let pro of profits">
      <div class="col-12">
        <div class="row title">
          <div class="col-12 px-0">
            {{pro.Title}}
          </div>
        </div>
        <div class="row total">
          <div class="col-12 px-0">
            {{pro.total}}
          </div>
        </div>
        <div class="row">
          <div class="col-12 container-percent">
            <div class="percent" [ngClass]="{'red': pro.percent < 0}" [ngStyle]="{'width': ((pro.percent >= 0) ? pro.percent: (pro.percent * -1)) + '%' }">&nbsp;</div>
            <div class="percent shadow">&nbsp;</div>
          </div>
        </div>
        <div class="row period">
          <div class="col-12 px-0">
            {{pro.percent | compared: pro.period}}
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
