import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VALID } from '../../config/config.constans';
import { AuthService } from '../../services/auth.service';
import { Store, Menu } from 'src/app/classes/store';
import { SocialUser } from 'angularx-social-login';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  activeHeader: boolean;
  stores: Store[];
  selected: string;
  storeForm: FormGroup;
  gUser: SocialUser;
  links: Menu[];
  logo: string;

  constructor(
    private route: ActivatedRoute,
    private config: ConfigService,
    private auths: AuthService,
    public fb: FormBuilder
  ) {
    this.activeHeader = false;
  }

  ngOnInit(): void {
    this.route.data
      .subscribe(data => {
        const { activeHeader } = data;
        this.activeHeader = activeHeader;
      });
    if (this.config.stores) {
      this.set(this.config.stores);
    }
    this.config.storesSubject
      .subscribe(stores => {
        this.set(stores);
      })
    this.gUser = this.auths.gUser;
    this.auths.gUserSubject
      .subscribe(gUser => this.gUser = gUser);

    this.links = this.config.sidebarOpts;
    this.config.sidebarOptsSubject
      .subscribe(links => this.links = links);

    if (this.config.selectedStore) {
      this.logo = this.config.selectedStore.theming.logo || '/assets/404notlogo.png';
    }
    this.config.selectedStoreSubject
      .subscribe(
        store => this.logo = store.theming.logo,
        err => {
          console.error(err);
          this.logo = '/assets/404notlogo.png';
        }
      )
  }

  set(stores: Store[]) {
    this.stores = stores;
    this.selected = this.config.selectedStore._id;
    this.storeForm = this.fb.group({
      store: [this.selected, [Validators.required]]
    });
    this.storeForm.statusChanges
      .subscribe(status => {
        if (status === VALID) {
          this.config.selectStore(this.storeForm.get('store').value);
        }
      });
  }

  goLogout(): void {
    this.auths.logOut()
  }

}
