import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface IProfit {
  Title: string;
  period: string;
  percent: number;
  total: number;
  order: number;
}

@Injectable({
  providedIn: 'root'
})
export class EcomerceService {

  constructor( ) { }

  getProfitData(idStore?: string) {
    return of([
      {
        Title: "Utilidades del Día",
        period: 'semana',
        percent: 70,
        total: 672030,
        order: 1
      },
      {
        Title: "Nuevos comentarios",
        period: 'semana',
        percent: -10,
        total: 420,
        order: 3
      },
      {
        Title: "Nuevas Ordenes",
        period: 'mes',
        percent: 30,
        total: 278,
        order: 2
      },
    ]).pipe(delay(1000));
  }
}
