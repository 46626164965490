import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compared'
})
export class ComparedPipe implements PipeTransform {
  fem = ['hora', 'semana'];

  transform(percent: number, period: string): string {
    if(percent > 0) {
      return `Mejor que hace un${(this.fem.indexOf(period) > 0)? 'a': ''} ${period} (${percent}%)`
    }
    if(percent < 0) {
      return `Peor que hace un${(this.fem.indexOf(period) > 0)? 'a': ''} ${period} (${percent}%)`
    }
    return `igual que hace un${(this.fem.indexOf(period) > 0)? 'a': ''} ${period} (${percent}%)`
  }
}
